.App {
  /*text-align: center;*/
  padding-left: 10px;
  padding-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/*.fill-window {*/
/*  height: 100%;*/
/*  position: absolute;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  !*overflow: hidden;*!*/
/*}*/

.fill-window {
  position: absolute;
  /*left: 0;*/
  top: 0;
  min-height: 100vh;
  min-width: 100vw;

  overflow-x: hidden ;
  overflow: hidden;
}

html, body{

  /*max-width: 0;*/
  /*max-height: 0;*/
  overflow-x: hidden ;
}